import {Injectable} from '@angular/core';
import {DatabaseService} from './database.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GroupService extends DatabaseService {

  constructor(public http: HttpClient) {
    super(http);
  }

  async exportGroups(filter: any, distributor) {
    return this.exportEntities('GROUP', filter, distributor);
  }

  async getGroup$(groupId: string) {
    return this.get$(groupId);
  }

  async getGroupLangs$(distributor: string, userId: string): Promise<any> {
    return this.getEntityLangs$(distributor, userId, null, null, 'group', 'group');
  }

  async getGroupByUserIdAndLang$(distributor: string, userId: string, lang: string) {
    const filter = {
      'userId': userId,
      'lang': lang
    };
    const groups = await this.getGroups$(distributor, filter);
    if (groups && groups.length > 0) {
      return groups[0];
    }
  }


  /*
  async createGroup$(distributor: string, group: any) {
    return this.save$(distributor, group);
  }
  */


  async getGroups$(distributor: string, filter: any): Promise<any> {
    if (!filter) {
      filter = {};
    }
    filter['type'] = 'GROUP';

    return this.getEntities$(distributor, filter, null, 'groups', 'group');
  }

  async getGroupsByUserId$(distributor: string, userId: string) {
    const filter = {
      'userId': userId
    };

    return this.getEntities$(distributor, filter, null, 'groups', 'group');
  }

  getPath() {
    return 'group';
  }

  getLabel() {
    return 'group';
  }

  getId() {
    return super.getId('groups');
  }

}
