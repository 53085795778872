import * as moment from 'moment';
import * as _ from 'lodash';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function formatSimpleDate() {
    return moment().format('HH:mm:ss[|]');
}

export function dateFormat() {
    return 'YYYY-MM-DD';
}
export function dateFormat2() {
    return 'DD/MM/YYYY';
}

export function isEmpty(obj) {
    return (obj == null || obj === undefined || obj === '');
}

export function isEmptyObj(obj) {
    if (isEmpty(obj)) {
        return true;
    }
    return Object.keys(obj).length === 0;
}


export function isObject(obj) {
    return typeof obj === 'object' && obj !== null;
}

export function createTranslateLoader(http: HttpClient) {
// export function createTranslateLoader(http: Http) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
