import {Injectable} from '@angular/core';
import {RoleType} from './enums/role-types';
import {SessionService} from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public RoleType: typeof RoleType = RoleType;

  constructor(public session: SessionService) { }

  public isAuthorized(requiredRoleType: RoleType) {
    if (requiredRoleType === RoleType.GUEST) {
      return true;
    }

    if (!this.session.user || !this.session.user.type) {
      return false;
    }

    const user = this.session.user;
    if (user.type === RoleType.ADMIN) {
      return true;
    }

    if (requiredRoleType === RoleType.ADMIN && user.type !== RoleType.ADMIN) {
      return false;
    }

    if (requiredRoleType === RoleType.DISTRIBUTOR) {
      if (user.type !== RoleType.DISTRIBUTOR) {
        return false;
      }

      const userDistributorId =  user.distributor.id;
      const currentDistributorId = this.session.getDistributorId();

      if (userDistributorId !== currentDistributorId) {
        return false;
      }
    }

    if (requiredRoleType === RoleType.GROUP) {
      if (user.type === RoleType.DISTRIBUTOR) {
        return true;
      }
      else if (user.type !== RoleType.GROUP) {
        return false;
      }
    }

    return true;
  }

  public disabledDistributor() {
    const user = this.session.user;
    if (user.type === RoleType.DISTRIBUTOR) {
      return false;
    }

    return true;
  }
}
